'use strict';

angular.module('casist.ubytovanie')
  .controller('IzbyCtrl', ['$scope', 'Restangular', 'Izba', 'Profile', '$controller', '$q', 'TabService', 'Outputs', '$modal', 'Notification', 'Karta', function ($scope, Restangular, Izba, Profile, $controller, $q, TabService, Outputs, $modal, Notification, Karta) {
    $scope.SearchCtrl = $controller('SearchCtrl');

    $scope.izby = [];
    var filterChanged = function(filter) {
      var promise = Izba.objects().getList(filter);
      $scope.izby.loading = true;
      promise.then(function(data) {
        TabService.registerScope('izby', $scope);
        $scope.izby = data;
      });
      return promise;
    };
    $scope.getKarta = function(query) {
      return Karta.get(query, undefined, undefined, {'je_ubytovanie': true});
    };
    $scope.izbyTable = [
      { },
      { field: 'izba', label: 'Izba', filterCollapse: 'right' },
      { field: 'posteli', label: 'Postelí', type: 'int', width: 50 },
      { field: 'cena_kratkodobe', label: 'Cena krátkodobé', type: 'number', width: 150 },
      { field: 'sluzba_kratkodobe', label: 'Služba krátkodobé', linked: { source: 'getKarta(query)', template: 'karty-selection-short', lookup: 'nazov', style: "{width: '400px'}", editable: false } },
      { field: 'cena_dlhodobe', label: 'Cena dlhodobé', type: 'number', width: 150 },
      { field: 'sluzba_dlhodobe', label: 'Služba dlhodobé', linked: { source: 'getKarta(query)', template: 'karty-selection-short', lookup: 'nazov', style: "{width: '400px'}", editable: false } },
      { field: 'cena_akcia', label: 'Cena akcia', type: 'number', width: 150 },
      { field: 'sluzba_akcia', label: 'Služba akcia', linked: { source: 'getKarta(query)', template: 'karty-selection-short', lookup: 'nazov', style: "{width: '400px'}", editable: false } },
      { field: 'vybavenie', label: 'Vybavenie', filterCollapse: 'left' },
      { field: 'aktivna', label: 'Aktívna', filterCollapse: 'left' }
    ];
    var loadPrevadzky = function() {
      $scope.prevadzky = _.filter(Profile.get('prevadzky'), {agenda: Profile.get('agenda').id});
    };
    loadPrevadzky();
    $scope.SearchCtrl.init({
      onChange: filterChanged,
      defaultFilter: {
        prevadzka: Profile.get('lastPrevadzka').id || $scope.prevadzky[0].id
      },
      columnsDef: $scope.izbyTable,
      sort: {sort: 'iddokd', reverse: false}
    });
    $scope.SearchCtrl.refresh(TabService.bus.get('izby'));

    var events = Izba.registerEvents(function() { return $scope.izby; }, function() { return $scope.SearchCtrl; });
    $scope.$on('$destroy', function() {
      Izba.unregisterEvents(events);
      for (var i in unregisterProfileObservers) {
        unregisterProfileObservers[i]();
      }
    });

    var unregisterProfileObservers = [];

    unregisterProfileObservers.push(Profile.addObserver('agenda', function() {
      $scope.SearchCtrl.cancelFilter(false);
      $scope.SearchCtrl.setLastPage();
      $scope.SearchCtrl.refresh();
    }));

    $scope.create = function(item) {
      if (!item) {
        item = {prevadzka: $scope.SearchCtrl.filters.top.prevadzka};
      }
      return Izba.edit(item, $scope.izby, $scope.SearchCtrl);
    };
    $scope.copy = function(item) {
      var copied = Izba.copy(item);
      _.each(['id', 'izba'], function(field) {
        delete copied[field];
      });
      $scope.create(copied);
    };
    $scope.exportData = function() {
      Outputs.openXLSExportUrl('ubytovanie/izby', $scope.SearchCtrl.getQuery());
    };
    $scope.openImport = function() {
      var modalInstance = $modal.open({
        templateUrl: 'core/import/import.html',
        controller: 'GenericImportCtrl',
        backdrop: 'static',
        windowClass: 'detail',
        resolve: {
          list: function() {
            return $scope.izby;
          },
          model: function() {
            return Izba;
          }
        }
      });
      modalInstance.result.then(function() {
        Notification.add('Záznamy boli aktualizované.', 'success', 5);
        $scope.SearchCtrl.refresh();
      });
    };
  }]);
