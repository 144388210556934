'use strict';

angular.module('casist.sklad').controller('FakturaciaCtrl',
  ['$scope', '$timeout', '$modal', 'Restangular', 'Socket', '$rootScope', 'Profile', '$controller', 'Faktura', 'Pohyb', 'Firma', 'Outputs', 'DokladTypy', 'TabService', 'Notification', 'Global', 'Mailbox',
  function ($scope, $timeout, $modal, Restangular, Socket, $rootScope, Profile, $controller, Faktura, Pohyb, Firma, Outputs, DokladTypy, TabService, Notification, Global, Mailbox) {

  $scope.slideAnimation = true;
  var agenda = Profile.get('agenda', false);
  $scope.isAgendaUbytovanie = agenda.ubytovanie;

  var defaultPeriod = {
    'd_zdp__gte': Profile.get('obdobie', new Date().getFullYear())+'-01-01',
    'd_zdp__lte': Profile.get('obdobie', new Date().getFullYear())+'-12-31'
  };
  var defaultFilter = {
    'dd': DokladTypy.VF.dd
  };
  $scope.faktury = [];
  var filterChanged = function(filter) {
    if (filter.neuhradene) {
      $scope.SearchCtrl.setDefaultPeriod(undefined);
      if (_.every(_.keys(defaultPeriod), function(key) {
        return filter[key] === defaultPeriod[key];
      })) {
        filter = _.omit(filter, 'd_zdp__gte');
      }
    }
    var promise = Faktura.objects().getList(filter);
    $scope.faktury.loading = true;
    promise.then(function(data) {
      TabService.registerScope('faktura', $scope);
      $scope.faktury = data;

      if ($scope.selectedAll) {
        for (var i = 0; i < $scope.faktury.length; i++) {
          $scope.faktury[i].selected = true;
        }
      } else {
        var faktura;
        _.every($scope.selectedItems, function(obj) {
          if ((faktura = _.find($scope.faktury, {id: obj.id}))) {
            $scope.selectedItems[obj.id] = faktura;
            $scope.selectedItems[obj.id].selected = true;
          }
        });
      }

    });
    if (filter.zahranicne)
      $scope.setCurrentView(1);
    return promise;
  };
  var unregisterAuthObservers = [];

  unregisterAuthObservers.push(Profile.addObserver('agenda', function() {
    $scope.SearchCtrl.cancelFilter(false);
    $scope.SearchCtrl.setLastPage();
    $scope.SearchCtrl.refresh();
  }));
  unregisterAuthObservers.push(Profile.addObserver('prevadzky', function() {
    loadPrevadzky();
  }));
  unregisterAuthObservers.push(Profile.addObserver('obdobie', function(obdobie) {
    defaultPeriod = {
      'd_zdp__gte': Profile.get('obdobie', new Date().getFullYear())+'-01-01',
      'd_zdp__lte': Profile.get('obdobie', new Date().getFullYear())+'-12-31'
    }
    $scope.SearchCtrl.init({
      defaultPeriod: defaultPeriod,
      onChange: filterChanged
    });
    $scope.SearchCtrl.setLastPage();
    $scope.SearchCtrl.refresh();
  }));
  $scope.SearchCtrl = $controller('SearchCtrl');
  $scope.Firma = Firma;

  var coreFields = [
    {},
    { selectAllAddon: true },
    {},
    { field: 'iddokd', label: 'ucto.RECORD', type: 'int', filterCollapse: 'right' },
    { field: 'vs', label: 'ucto.VS', type: 'int', filterType: 'icontains' }
  ];

  $scope.fakturyTable = [
    _.union(coreFields, [
      { field: 'd_vyst', label: 'ucto.ISSUED', type: 'date' },
      { field: 'd_zdp', label: 'ucto.SUPPLIED', type: 'date' },
      { field: 'd_splat', label: 'ucto.DUE', type: 'date' },
      { field: 'firma__firma', label: 'firmy.FIRMA', placeholder: 'ucto.FIRMA_ICO', linked: { source: 'Firma.get(query)', template: 'linked-element-match-table', lookup: 'firma', style: "{width: '400px'}" } },
      { field: 'spolu', label: 'ucto.SPOLU', type: 'number', sum: true },
      { field: 'uhradene', label: 'ucto.UHRADENE', type: 'number', sum: true },
      { field: 'rozdiel', label: 'ucto.ROZDIEL', type: 'number', sum: function(sums) { return parseFloat(sums['spolu']) - parseFloat(sums['uhradene']);} },
      { field: 'zaklad_d1', label: 'ucto.ZAKLAD1', type: 'number', sum: true },
      { field: 'd1', label: 'ucto.DPH1', type: 'number', sum: true },
      { field: 'zaklad_d2', label: 'ucto.ZAKLAD2', type: 'number', sum: true },
      { field: 'd2', label: 'ucto.DPH2', type: 'number', sum: true },
      { field: 'zaklad_d0', label: 'ucto.ZAKLAD0', type: 'number', sum: true },
      { field: 'uplat_dph', label: 'ucto.MDPH', type: 'int', searchable: false },
      { field: 'typ_dph__typ', label: 'ucto.TDPH', width: 70 },
      { field: 'zlava', filterCollapse: 'left', label: 'ucto.ZLAVA', type: 'number' },
      { field: 'skontrolovana', sortField: 'skontrolovana', icon: '<i class="fa fa-check"></i>', placeholder: 'Skontrolovaná', tooltip: 'Skontrolovaná', filterCollapse: 'left'},
      { field: 'odoslana', sortField: 'odoslana', icon: '<i class="fa fa-envelope"></i>', placeholder: 'Odoslaná faktúra', tooltip: 'Odoslaná faktúra', filterCollapse: 'left'},
      { field: 'odoslana_dopravcovi', sortField: 'odoslana_dopravcovi', icon: '<i class="fa fa-truck"></i>', placeholder: 'Odoslaná dopravcovi', tooltip: 'Odoslaná dopravcovi', filterCollapse: 'left'},
      { field: 'uhradena', sortField: 'uhradena', icon: '<i class="fa fa-dollar"></i>', placeholder: 'Uhradená', tooltip: 'Uhradená', filterCollapse: 'left'},
      { field: 'odoslana_refis', sortField: 'odoslana_refis', icon: '<i class="fa fa-file"</i>', placeholder: 'Odoslaná do ReFIS', tooltip: 'Odoslaná do ReFIS', filterCollapse: 'left'},
    ]),
    _.union(coreFields, [
      { field: 'd_zdp', label: 'ucto.SUPPLIED', type: 'date' },
      { field: 'firma__firma', label: 'firmy.FIRMA', placeholder: 'ucto.FIRMA_ICO', linked: { source: 'Firma.get(query)', template: 'linked-element-match-table', lookup: 'firma', style: "{width: '400px'}" } },
      { field: 'spolu', label: 'ucto.SPOLU', type: 'number', sum: true },
      { field: 'uhradene', label: 'ucto.UHRADENE', type: 'number', sum: true },
      { field: 'rozdiel', label: 'ucto.ROZDIEL', type: 'number', sum: function(sums) { return parseFloat(sums['spolu']) - parseFloat(sums['uhradene']);} },
      { field: 'vynosy', label: 'Výnosy', type: 'number', searchable: false, sortable: false },
      { field: 'naklady', label: 'Náklady', type: 'number', searchable: false, sortable: false },
      { field: 'zisk', label: 'Zisk', type: 'number', searchable: false, sortable: false },
      { field: 'spolu_zm', label: 'ucto.SPOLUZM', type: 'number' },
      { field: 'kurz', label: 'ucto.KURZ', type: 'number' },
      { field: 'mena', label: 'ucto.MENA', width: 90 },
      { field: 'sd1', label: 'ucto.SD1', type: 'int', searchable: false, sortable: false },
      { field: 'sd2', label: 'ucto.SD2', type: 'int', searchable: false, sortable: false },
      { field: 'objednavka', label: 'ucto.OBJEDNAVKA', filterType: 'icontains' },
      { field: 'doprava', label: 'ucto.DOPRAVA', filterType: 'icontains' },
      { field: 'predajca', label: 'ucto.PREDAJCA', filterType: 'icontains' },
      { field: 'poznamka', label: 'fakturacia.POZNAMKA', filterType: 'icontains' }
    ]),
  ];

  if (!Profile.get('platcadph')) {
    var omitted = ['zaklad_d1', 'd1', 'zaklad_d2', 'd2', 'zaklad_d0', 'uplat_dph', 'typ_dph__typ', 'sd1', 'sd2'];
    var idx = $scope.fakturyTable[0].indexOf(_.find($scope.fakturyTable[0], {field: 'zaklad_d1'}));
    $scope.fakturyTable[0] = _.reject($scope.fakturyTable[0], function(obj) { return omitted.indexOf(obj.field) !== -1;});
    $scope.fakturyTable[1] = _.reject($scope.fakturyTable[1], function(obj) { return omitted.indexOf(obj.field) !== -1;});
    var moved = ['objednavka', 'doprava', 'predajca'];
    var movedItems = _.filter($scope.fakturyTable[1], function(obj) { return moved.indexOf(obj.field) !== -1;});
    for (var i = movedItems.length-1; i >= 0; i--) {
      $scope.fakturyTable[0].splice(idx, 0, movedItems[i]);
    }
    $scope.fakturyTable[1] = _.reject($scope.fakturyTable[1], function(obj) { return moved.indexOf(obj.field) !== -1;});
  }

  var loadPrevadzky = function() {
    $scope.prevadzky = _.filter(Profile.get('prevadzky'), {agenda: Profile.get('agenda').id});
  };
  loadPrevadzky();

  $scope.create = function(item) {
    var modalInstance = $modal.open({
      templateUrl: 'sklad/fakturacia/fakturacia-detail.html',
      controller: 'FakturaciaDetailCtrl',
      backdrop: 'static',
      keyboard: false,
      windowClass: 'detail',
      resolve: {
        faktura: function() {
          return item ? item : {};
        },
        faktury: function() {
          return $scope.faktury;
        },
        SearchCtrl: function() {
          return $scope.SearchCtrl;
        },
        config: function() {
          return {hlavicky: true, paty: true, predajca: true, doprava: true};
        }
      }
    });

    modalInstance.result.then(null, null);
  };

  $scope.copy = function(item) {
    var copied = Faktura.copy(item);
    copied.getList('pohyb').then(function(pohyb) {
      copied.pohyb = pohyb;
      _.each(
        ['id', 'vs', 'd_vyst', 'd_zdp', 'd_splat', 'd_uctn', 'uplat_dph', 'uhradene', 'locked', 'odoslana',
         'skontrolovana', 'odoslana_dopravcovi', 'odoslana_refis'
        ], function(field) {
        delete copied[field];
      });
      $scope.create(copied);
    });
  };
  var events = Faktura.registerEvents(function() { return $scope.faktury; }, function() { return $scope.SearchCtrl; });
  $scope.$on('$destroy', function() {
    Faktura.unregisterEvents(events);
    for (var i in unregisterAuthObservers) {
      unregisterAuthObservers[i]();
    }
  });

  $scope.SearchCtrl.init({
    model: Faktura,
    defaultPeriod: defaultPeriod,
    defaultFilter: defaultFilter,
    onChange: filterChanged,
    columnsDef: $scope.fakturyTable[0],
    sort: {sort: 'iddokd', reverse: false},
    scope: $scope
  });

  $scope.SearchCtrl.refresh(TabService.bus.get('faktura'));

  $scope.views = [ 'main.ZAKLADNE', 'main.OSTATNE' ];
  $scope.currentView = 0;
  $scope.setCurrentView = function(view) {
    $scope.currentView = view;
    $scope.SearchCtrl.changeColumnsDef($scope.fakturyTable[view]);
  };

  $scope.print = function(faktura, url) {
    Outputs.openPrintUrl('faktury', faktura.id, undefined, url);
  };

  $scope.exportEdi = function(faktura, url) {
    if (faktura.spolu <= 0 && url === 'skupinova-edi') {
      Notification.add("EDI export zlyhal. Suma spolu nie je kladná.", "danger", 10);
      return;
    }
    if (faktura.spolu >= 0 && url === 'skupinovy-dobropis') {
      Notification.add("EDI export zlyhal. Suma spolu nie je záporná.", "danger", 10);
      return;
    }
    Outputs.openEdiExportUrl('faktury', faktura.id, undefined, url);
  };

    $scope.uhradarp = {};
    $scope.stornorp = {};
    $scope.showRP = function(faktura, storno) {
      if (faktura.spolu - faktura.uhradene == 0 && !storno) {
        return;
      }
      var v = storno ? $scope.stornorp : $scope.uhradarp;
      v[faktura.id] = '<div><i class="fa fa-info"></i> Pred presunom do RP sa uistite, že máte spustený program registračnej pokladne a vybranú rovnakú agendu.</div><div class="text-center" style="margin-top: 5px;"><button class="btn btn-sm btn-default" ng-click="presunDoRP('+faktura.id+', '+storno+')"><i class="fa fa-money"></i> Presun do RP</button></div>';
    };
    $scope.uhradaClosed = function(faktura) {
      delete $scope.uhradarp[faktura.id];
    };
    $scope.stornoClosed = function(item) {
      delete $scope.stornorp[item.id];
    };
    $scope.presunDoRP = function(id, storno) {
      var faktura = _.find($scope.faktury, {id: parseInt(id)});
      if (faktura) {
        Socket.emit('payInvoice', {user: Profile.get('userData').id, id: faktura.id, iddokd: faktura.vs, uhradene: faktura.uhradene, spolu: storno ? ((-1) * faktura.uhradene) : (faktura.spolu - faktura.uhradene), firma: faktura.firma, firma_name: faktura.firma_data.firma});
      }
      $timeout(function() {
        storno ? delete $scope.stornorp[id] : delete $scope.uhradarp[id];
      });
    };

    $scope.exportItems = function(faktura, url) {
      Outputs.openExportUrlWithID('faktury', faktura.id, undefined, url);
    };

    $scope.pohodaXMLExport = function() {
      Outputs.openXMLExportUrl('faktury', 'pohoda', $scope.SearchCtrl.getQuery());
    };

  var updateOznacenych = function() {
    var selectedItemsCount = _.keys($scope.selectedItems).length;
    switch (selectedItemsCount) {
      case 0:
        $scope.selectedItemsText = '';
        break;
      case 1:
        $scope.selectedItemsText = 'Označený '+ selectedItemsCount +' záznam';
        break;
      case 2: case 3: case 4:
        $scope.selectedItemsText = 'Označené '+ selectedItemsCount +' záznamy';
        break;
      default:
        $scope.selectedItemsText = 'Označených '+ selectedItemsCount +' záznamov';
        break;
    }
  };

  $scope.selectedItems = {};
  $scope.selectedAll = false;
  $scope.selectAll = function() {
    var selectedAll = !$scope.selectedAll;
    $scope.selectedItems = {};
    for (var i = 0; i < $scope.faktury.length; i++) {
      $scope.faktury[i].selected = selectedAll;
      if (selectedAll) {
        $scope.selectedItems[$scope.faktury[i].id] = $scope.faktury[i];
      } else {
        delete $scope.selectedItems[$scope.faktury[i].id];
      }
    }
    updateOznacenych();
  };

  $scope.selectFaktura = function(faktura) {
    if ($scope.selectedAll) {
      $scope.selectedAll = false;
    }
    if ($scope.selectedItems[faktura.id]) {
      delete $scope.selectedItems[faktura.id];
    } else {
      $scope.selectedItems[faktura.id] = faktura;
    }
    updateOznacenych();
  };

  $scope.sendEmails = function () {
    var ids = Object.keys($scope.selectedItems);

    Faktura.sendEmails(ids)
    .then(
      function (response) {
        _.every($scope.selectedItems, function(selectedItem) {
          delete $scope.selectedItems[selectedItem.id];
          var faktura = _.find($scope.faktury, {id: parseInt(selectedItem.id)});
          faktura.selected = false;
          faktura.odoslana = true;
          Faktura.syncChange(faktura);
        });

        _.every(response.data, function(mailMessage) {
          Mailbox.syncCreate(mailMessage);
        });

        if ($scope.selectedAll) {
          $scope.selectedAll = false;
        }
        updateOznacenych();

        $scope.SearchCtrl.refresh();
        Notification.add(response.msg, 'success', 10);
      }
    ).catch(
      function (error) {
        if (error.status >= 400 && error.status < 500) {
          Notification.add(error.data, 'danger', 10);
        }
      }
    );
  };

  var _updateBulk = function (faktury) {
    Faktura.updateBulk(faktury)
    .then(
      function () {
        _.each(faktury, function (faktura) {
          delete faktury[faktura.id];
          _.find($scope.faktury, {id: parseInt(faktura.id)}).selected = false;
          Faktura.syncChange(faktura);
        });

        if ($scope.selectedAll) {
          $scope.selectedAll = false;
        }
        updateOznacenych();
        $scope.SearchCtrl.refresh();
        Notification.add('Zvolené faktúry boli úspešne upravené.', 'success', 10);
      }
    ).catch(
      function (error) {
        if (error.status >= 400 && error.status < 500) {
          Notification.add('Faktúry sa nepodarilo upraviť.', 'danger', 10);
        }
      }
    );
  };

    $scope.setShipmentStatus = function (status) {
    _.each($scope.selectedItems, function(faktura) {
      faktura.odoslana_dopravcovi = status;
    });
    _updateBulk($scope.selectedItems);
  };

  $scope.setSkontrolovanaStatus = function (status) {
    _.each($scope.selectedItems, function(faktura) {
      faktura.skontrolovana = status;
    });
    _updateBulk($scope.selectedItems);
  };

  $scope.setOdoslanaStatus = function (status) {
    var neskontrolovaneFaktury = _.filter($scope.selectedItems, function(faktura) {
      return !faktura.skontrolovana;
    });
    var agenda = Profile.get('agenda');
    if (agenda.ubytovanie && neskontrolovaneFaktury.length > 0) {
      _.each($scope.selectedItems, function(faktura) {
        Notification.add('Faktúra ' + faktura.vs + ' nie je skontrolovaná.', 'danger', 10);
      });
    } else {
      _.each($scope.selectedItems, function(faktura) {
        faktura.odoslana = status;
      });
      _updateBulk($scope.selectedItems);
    }
  };

  $scope.setOdoslanaReFISStatus = function (status) {
    const neskontrolovaneFaktury = _.filter($scope.selectedItems, function(faktura) {
      return !faktura.skontrolovana;
    });

    const agenda = Profile.get('agenda');
    if (agenda.ubytovanie && neskontrolovaneFaktury.length > 0) {
      _.each($scope.selectedItems, function(faktura) {
        Notification.add(`Faktúra ${faktura.vs} nie je skontrolovaná a/alebo nebola odoslaná.`, 'danger', 10);
      });
    } else {
      _.each($scope.selectedItems, function (faktura) {
        faktura.odoslana_refis = status;
      });
      _updateBulk($scope.selectedItems);
    }
  };

  $scope.setUhradenaStatus = function (status) {
    var neskontrolovaneFaktury = _.filter($scope.selectedItems, function(faktura) {
      return !faktura.skontrolovana;
    });
    var neodoslaneFaktury = _.filter($scope.selectedItems, function(faktura) {
      return !faktura.odoslana;
    });

    var agenda = Profile.get('agenda');
    if (agenda.ubytovanie && neskontrolovaneFaktury.length > 0) {
      _.each($scope.selectedItems, function(faktura) {
        Notification.add('Faktúra ' + faktura.vs + ' nie je skontrolovaná a/alebo nebola odoslaná.', 'danger', 10);
      });
    }
    else if (agenda.ubytovanie && neodoslaneFaktury.length > 0) {
      _.each($scope.selectedItems, function(faktura) {
        Notification.add('Faktúra ' + faktura.vs + ' nebola odoslaná.', 'danger', 10);
      });
    } else {
      _.each($scope.selectedItems, function (faktura) {
        faktura.uhradena = status;
      });
      _updateBulk($scope.selectedItems);
    }
  };

  $scope.createOrderShipment = function (faktura, shipmentType) {
    Faktura.createOrderShipment(faktura, shipmentType)
    .then(
      function () {
        Faktura.syncChange(faktura);
        $scope.SearchCtrl.refresh();
        Notification.add(`Faktúru sa podarilo úspešne odoslať do ${shipmentType}.`, 'success', 10);
      }
    ).catch(
      function (error) {
        if (error.status >= 400 && error.status < 500) {
          Notification.add(error.data, 'danger', 10);
        } else {
          Notification.add('Nastala serverová chyba.', 'danger', 10);
        }
      }
    );
  };

  $scope.createOrderShipments = function (shipmentType) {
    Faktura.createOrderShipments($scope.selectedItems, shipmentType)
    .then(
      function () {
        _.each($scope.selectedItems, function (faktura) {
          delete $scope.selectedItems[faktura.id];
          _.find($scope.faktury, {id: parseInt(faktura.id)}).selected = false;
          Faktura.syncChange(faktura);
        });

        if ($scope.selectedAll) {
          $scope.selectedAll = false;
        }
        updateOznacenych();
        $scope.SearchCtrl.refresh();
        Notification.add(`Faktúry sa podarilo úspešne odoslať do ${shipmentType}.`, 'success', 10);
      }
    ).catch(
      function (error) {
        if (error.status >= 400 && error.status < 500) {
          Notification.add(error.data, 'danger', 10);
        } else {
          Notification.add('Nastala serverová chyba.', 'danger', 10);
        }
      }
    );
  };

  $scope.printShipmentLabels = function(shipmentType) {
    var ids = Object.keys($scope.selectedItems);
    Outputs.openShipmentUrl(`faktury/shipment/${shipmentType}/printlabels`, {'ids': ids});
  };

  $scope.sendToReFIS = function () {
    const odoslaneFaktury = _.filter($scope.selectedItems, function(faktura) {
      return faktura.odoslana_refis;
    });
    const neodoslaneFaktury = _.filter($scope.selectedItems, function(faktura) {
      return !faktura.odoslana_refis;
    });
    _.each(odoslaneFaktury, function(faktura) {
      Notification.add(`Faktúra ${faktura.vs} už bola odoslaná. V prípade, že chcete faktúru opäť odoslať, je potrebné ju označiť ako neodoslanú.`, 'danger', 10);
    });

    Faktura.sendToReFIS(neodoslaneFaktury)
    .then(
      function () {
        _.each($scope.selectedItems, function (faktura) {
          delete $scope.selectedItems[faktura.id];
          _.find($scope.faktury, {id: parseInt(faktura.id)}).selected = false;
          Faktura.syncChange(faktura);
        });

        if ($scope.selectedAll) {
          $scope.selectedAll = false;
        }
        updateOznacenych();
        $scope.SearchCtrl.refresh();
        Notification.add('Faktúry sa podarilo odoslať do ReFIS.', 'success', 10);
      }
    ).catch(
      function (error) {
        if (error.status >= 400 && error.status < 500) {
          Notification.add(error.data, 'danger', 10);
        } else {
          Notification.add('Nastala serverová chyba.', 'danger', 10);
        }
      }
    );
  };
  /*$scope.topFilter = '';
  $scope.filterFirma = function(f) {
    $scope.topFilter = f;
    $scope.fakturyFilter.filterText = $scope.topFilter+";"+$scope.myFilter;
    $scope.firmaFilter = "";
  }
  $scope.$watch('myFilter', function (value) {
    $scope.fakturyFilter.filterText = $scope.topFilter+";"+value;
  });
  $scope.pohyb = [];
  $scope.alerts = [];
  $scope.mySelections = [];
  $scope.myFilter = "";
  $scope.fakturyFilter = {
    filterText: $scope.topFilter+";"+$scope.myFilter
  }

  $scope.edit = function (item) {
    $scope.create(item);
  }
  var tableControls = '<a href="" ng-click="edit(row.rowIndex)" style="color: black;"><i class="fa fa-pencil" style="color: #444" tooltip="Upraviť"></i></a>';
  var lastSelected = 0;

  $scope.gridOptions = {
      data: 'faktury',
      headerRowTemplate: '<div ng-style="{ height: col.headerRowHeight }" ng-repeat="col in renderedColumns" ng-class="col.colIndex()" class="ngHeaderCell" ng-header-cell></div>',
      rowTemplate: '<div ng-dblclick="onDblClick(row)" ng-style="{ \'cursor\': row.cursor }" ng-repeat="col in renderedColumns" ng-class="col.colIndex()" class="ngCell {{col.cellClass}}"><div class="ngVerticalBar" ng-style="{height: rowHeight}" ng-class="{ ngVerticalBarVisible: !$last }">&nbsp;</div><div ng-if="$first">'+tableControls+'</div><div ng-if="!$first" ng-cell></div></div>',
      columnDefs: [{field:'', displayName:'', width: '40', cellClass: 'text-center'}, {field:'iddokd', displayName:'Doklad', width: '100'}, {field:'d_vyst', displayName:'Vystavenie', cellFilter: "date:\"dd.MM.yyyy\""}, {field:'firma', displayName:'Firma'}, {field:'spolu', displayName:'Spolu', cellFilter: 'number:2', cellClass: 'text-right'}, {field:'poznamka', displayName:'Poznámka'}],
      // groups: ['iddokd'],
      enableColumnResize: true,
      multiSelect: false,
      // keepLastSelected: false,
      showColumnMenu: false,
      showSelectionCheckbox: false,
      showFilter: false,
      selectedItems: $scope.mySelections,
      enableCellSelection: false,
      enableCellEdit: false,
      rowHeight: 22,
      filterOptions: $scope.fakturyFilter,
      sortInfo: {fields: ['iddokd'], directions: ['asc']},
      afterSelectionChange: function(data) {
          if (data.selected) {
              if (lastSelected != $scope.mySelections[0].id) {
                  $scope.pohyb = [];
                  lastSelected = $scope.mySelections[0].id;
                  // $scope.pohybOptions.data = $scope.messages[$scope.mySelections[0].id];
                  // $scope.pohyb.push($scope.pohyb_all[$scope.mySelections[0].id]);

                  // Temporarily removed
                  // $scope.pohyb = DB.resource('pohyb').query({'faktura': $scope.mySelections[0].id});
              }
          } else {
              $scope.pohyb = [];
          }
      },
  };
  $scope.closeAlert = function (index) {
      $scope.alerts.splice(index, 1);
  }
  $scope.onDblClick = function(row) {
      console.log('double  click: '+$scope.mySelections[0].id);
      var faktura = $scope.mySelections[0];
      $scope.create(faktura);
  }*/
  // !! Event na resizovanie stlpcov !!
  /*$scope.$on('ngGridEventColumns', function (newColumns) {
          angular.forEach (newColumns.targetScope.columns, function (column) {
              if (column.width != $scope.gridOptions.columnDefs[column.index].width) {
                  // console.log("Column: ");
                  // console.log(column);
                  // $scope.gridOptions.columnDefs[column.index].width = column.width;
              }
          });
      });*/
  }]);
